import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const interactionInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

// Add a star to a block, if true the block will be starred if false the block will be unstarred
// The parameter can be omitted and it will simply flip the value in the backend
export const starBlock = async (block, star) => {
  const response = await interactionInstance.patch(
    `interactions/${block}/star`,
    {
      star: star,
    },
  );

  return response.data;
};

export const getStars = async (block) => {
  return await interactionInstance.get(`interactions/${block}/stars`);
};

export const isStarred = async (block) => {
  const response = await interactionInstance.get(
    `interactions/${block}/starred`,
  );
  return response.data;
};
