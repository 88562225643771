import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const fileInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const updateFileName = async (id, fileName) => {
  return await fileInstance.put(`file/${id}/name`, { name: fileName });
};

export const updateFileDescription = async (id, fileDescription) => {
  return await fileInstance.put(`file/${id}/description`, { description: fileDescription });
};

export const getStars = async (fileId) => {
  return await fileInstance.get(`/file/${fileId}/stars`);
};
