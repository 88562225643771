<template>
  <div>
    <v-snackbar v-model="showSnackbar" :timeout="5000" bottom color="error">
      <div class="snackbar-content">
        <span>An error has occurred on our side!</span>
        <!-- Cross icon button -->
        <v-icon @click="closeSnackbar" class="close-icon">mdi-close</v-icon>
      </div>
    </v-snackbar>
    <slot />
  </div>
</template>

<script>
export default {
  data() {
    return {
      hasError: false,
      errorInfo: null,
      showSnackbar: false,
    };
  },

  methods: {
    setupGlobalOnError() {
      window.onerror = (message, source, lineno, colno, error) => {
        this.handleError(
          error || new Error(message),
          `${source}:${lineno}:${colno}`,
        );
      };

      window.addEventListener("error", this.handleErrorGlobal);
      window.addEventListener("unhandledrejection", this.handleErrorPromise);
    },

    handleError(error, info) {
      try {
        console.error("Error caught by boundary:", error);
        console.error("Additional info:", error, info);
        this.hasError = true;
        this.errorInfo = info || (error && error.message);
        this.showSnackbar = true;
      } catch (catchError) {
        console.error("Error while handling another error:", catchError);
      }
    },

    handleErrorGlobal(event) {
      this.handleError(event.error, "Unhandled error");
    },

    handleErrorPromise(event) {
      this.handleError(event.reason, "Unhandled promise rejection");
    },

    closeSnackbar() {
      this.showSnackbar = false;
    },
  },
  mounted() {
    this.setupGlobalOnError();
  },
  beforeUnmount() {
    window.onerror = null;
    window.removeEventListener("error", this.handleErrorGlobal);
    window.removeEventListener("unhandledrejection", this.handleErrorPromise);
  },
};
</script>

<style scoped>
.snackbar-content {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.close-icon {
  cursor: pointer;
}
</style>
