<script setup>
import { onMounted, ref, useSlots, defineProps } from "vue";
import * as utils from "./utils";
import { state } from "./state";

const slots = useSlots();
const hasSlot = !!slots.default;

//TODO: UG This returns a warning maybe in the future we will fix the warn:
// [@vue/compiler-sfc] `defineProps` is a compiler macro and no longer needs to be imported.
const props = defineProps({
  clientId: String,
  prompt: Boolean,
  autoLogin: Boolean,
  popupType: String,
  idConfiguration: Object,
  buttonConfig: Object,
  callback: Function,
  error: Function,
});

const options = utils.mergeObjects(state, props);

const buttonRef = ref();

const openPopup = (type) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Opening google popup", type);
  }

  if (type === "CODE") {
    utils
      .googleAuthCodeLogin({ clientId: options.clientId })
      .then((response) => options.callback && options.callback(response))
      .catch((error) => options.error && options.error(error));
  } else if (type === "TOKEN") {
    utils
      .googleTokenLogin({ clientId: options.clientId })
      .then((response) => options.callback && options.callback(response))
      .catch((error) => options.error && options.error(error));
  } else {
    console.error(`Type ${type} is not supported`);
  }
};

onMounted(() => {
  utils.onMount(
    {
      client_id: options.clientId || null,
      auto_select: options.autoLogin || false,
      callback: options.callback,
      use_fedcm_for_prompt: true,
      ...options.idConfiguration,
    },
    buttonRef,
    options,
    hasSlot,
  );

  if (!hasSlot) {
    console.warn("Slot not found");
  }
});
</script>

<template>
  <div class="g-btn-wrapper" @click="hasSlot && openPopup(options.popupType)">
    <span v-if="!hasSlot" ref="buttonRef" class="g-btn"></span>
    <slot></slot>
  </div>
</template>

<style scoped>
.g-btn-wrapper {
  display: inline-block;
}
</style>
