<template>
  <transition name="fade">
    <v-container v-if="isLoading">
      <v-container class="box px-4 py-2 text-caption text-center">
        {{ message }}
      </v-container>
    </v-container>
  </transition>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { isLoading } from "@/api"; // Import the global loading state

const messages = [
  "I'm thinking deeply about this...",
  "Working diligently to get this done...",
  "Processing your request, please be patient...",
  "Hold on, we're almost there...",
  "Waiting for the magic to happen...",
  "Loading your data, just a moment...",
];
const message = ref("");

function changeMessage() {
  message.value = messages[Math.floor(Math.random() * messages.length)];
}

onMounted(() => {
  changeMessage();
  setInterval(changeMessage, 2000); // Change message every 2 seconds
});
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}
.box {
  background: hsl(0, 0%, 100%);
  padding: 16px 24px;
  border-radius: 16px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.01);
  position: relative;
  max-width: 400px;

  &::after {
    position: absolute;
    content: "";
    top: 10px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(1) translateZ(0);
    filter: blur(10px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
</style>
