<template>
  <v-container
    class="d-flex align-center logo text no-select"
    @click="$router.push('/')"
  >
    <span class="deco d-none d-sm-block mr-2"></span>
    <span class="d-none d-sm-block hand-cursor">{{ $appName }}</span>
  </v-container>
</template>

<script setup>
import { onMounted } from "vue";

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped>
.logo {
  font-size: 1.5rem;
  font-weight: 300;
  font-family: "Comfortaa";
}

.logo .deco {
  background-image: linear-gradient(120deg, #f6d365 0%, #ff6034 100%);
  width: 2rem;
  height: 2rem;
}
</style>
