<!-- eslint-disable max-len -->
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";
import GoogleLogin from "@/plugins/google-login/GoogleLogin.vue";
import AppLogo from "@/components/AppLogo.vue";
import { useAuthStore } from "@/stores/auth.store";
import { hasWorkspace } from "@/api/workspace.client";

const router = useRouter();
const authStore = useAuthStore();

const hasErrors = ref(false);
const isNetworkError = ref(false);
const isDevelopmentMode = process.env.NODE_ENV === "development";

const loginCallback = async (value) => {
  if (process.env.NODE_ENV === "development") {
    console.info("Login callback", value);
  }

  try {
    await authStore.signInAsync(value);
  } catch (error) {
    if (error.isAxiosError && error.code === "ERR_NETWORK") {
      console.error("Network error occurred during sign-in:", error);
      isNetworkError.value = hasErrors.value = true;
    } else {
      console.error("An error occurred during sign-in:", error);
      hasErrors.value = true;
    }
  }

  nextTick(() => {
    router.push({ name: "workspace" });
  });
};

const loginError = (value) => {
  hasErrors.value = true;
  console.error(value);
};

onMounted(async () => {
  if (authStore.hasToken) {
    const result = await hasWorkspace();

    if (process.env.NODE_ENV === "development") {
      console.log("User has workspace call result.", result);
    }

    if (result) {
      nextTick(() => {
        this.$router.push({ name: "workspace" });
      });
    }
  }
});
</script>

<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <v-container class="login-card">
      <v-row v-if="!authStore.hasToken">
        <v-col cols="12">
          <AppLogo class="d-flex align-center justify-center" />
        </v-col>
        <v-col cols="12" class="text-center">
          {{ $t("choose-your-language") }}
        </v-col>

        <v-row v-if="hasErrors">
          <v-col cols="12" class="text-center">
            <p class="title">{{ $t("login.error") }}</p>
            <p class="text-overline" v-if="!isNetworkError">
              {{ $t("login.tryAgain") }}
            </p>
            <p class="text-overline" v-else>
              Seems a connection error to our systems.<br />Please refresh the
              page
            </p>

            <template v-if="isDevelopmentMode && isNetworkError">
              <v-divider class="my-4"></v-divider>
              <p class="text-caption text-error">
                This is a development environment. Did you start the backend
                locally or configured the FE $useRemoteApi variable?
              </p>
            </template>
          </v-col>
        </v-row>
        <v-col
          v-else
          cols="12"
          class="d-flex flex-column justify-center align-center"
        >
          <GoogleLogin
            :callback="loginCallback"
            :error="loginError"
            popup-type="CODE"
          >
            <button class="gsi-material-button">
              <div class="gsi-material-button-state"></div>
              <div class="gsi-material-button-content-wrapper">
                <div class="gsi-material-button-icon">
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 48 48"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    style="display: block"
                  >
                    <path
                      fill="#EA4335"
                      d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                    ></path>
                    <path
                      fill="#4285F4"
                      d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                    ></path>
                    <path
                      fill="#FBBC05"
                      d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                    ></path>
                    <path
                      fill="#34A853"
                      d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                    ></path>
                    <path fill="none" d="M0 0h48v48H0z"></path>
                  </svg>
                </div>
                <span class="gsi-material-button-contents"
                  >Sign in with Google</span
                >
                <span style="display: none">Sign in with Google</span>
              </div>
            </button>
          </GoogleLogin>
        </v-col>
      </v-row>

      <v-row v-if="authStore.hasToken">
        <v-col cols="12">
          <AppLogo class="d-flex align-center justify-center" />
        </v-col>
        <v-col cols="12">
          <h1 class="text-center">
            {{ $t("login.welcome", { name: authStore.userName }) }}
          </h1>
          <p class="text-center">{{ $t("login.settingsUp") }}</p>
        </v-col>
        <v-col
          v-if="authStore.userPicture"
          cols="12"
          class="d-flex align-center justify-center"
        >
          <v-avatar size="large">
            <v-img
              :src="authStore.userPicture"
              alt="t('profile-picture')"
              class="d-flex align-center justify-center"
              referrerpolicy="no-referrer"
            >
            </v-img>
          </v-avatar>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<style scoped>
.login-card {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  padding: 35px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(64px);
  color: black;
  background-color: rgba(255, 255, 255, 0.5);
}

.gsi-material-button {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: WHITE;
  background-image: none;
  border: 1px solid #747775;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #1f1f1f;
  cursor: pointer;
  font-family: "Roboto", arial, sans-serif;
  font-size: 14px;
  height: 40px;
  letter-spacing: 0.25px;
  outline: none;
  overflow: hidden;
  padding: 0 12px;
  position: relative;
  text-align: center;
  -webkit-transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  transition:
    background-color 0.218s,
    border-color 0.218s,
    box-shadow 0.218s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
  max-width: 400px;
  min-width: min-content;
}

.gsi-material-button .gsi-material-button-icon {
  height: 20px;
  margin-right: 12px;
  min-width: 20px;
  width: 20px;
}

.gsi-material-button .gsi-material-button-content-wrapper {
  -webkit-align-items: center;
  align-items: center;
  display: flex;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  flex-wrap: nowrap;
  height: 100%;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

.gsi-material-button .gsi-material-button-contents {
  -webkit-flex-grow: 1;
  flex-grow: 1;
  font-family: "Roboto", arial, sans-serif;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: top;
}

.gsi-material-button .gsi-material-button-state {
  -webkit-transition: opacity 0.218s;
  transition: opacity 0.218s;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.gsi-material-button:disabled {
  cursor: default;
  background-color: #ffffff61;
  border-color: #1f1f1f1f;
}

.gsi-material-button:disabled .gsi-material-button-contents {
  opacity: 38%;
}

.gsi-material-button:disabled .gsi-material-button-icon {
  opacity: 38%;
}

.gsi-material-button:not(:disabled):active .gsi-material-button-state,
.gsi-material-button:not(:disabled):focus .gsi-material-button-state {
  background-color: #303030;
  opacity: 12%;
}

.gsi-material-button:not(:disabled):hover {
  -webkit-box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
  box-shadow:
    0 1px 2px 0 rgba(60, 64, 67, 0.3),
    0 1px 3px 1px rgba(60, 64, 67, 0.15);
}

.gsi-material-button:not(:disabled):hover .gsi-material-button-state {
  background-color: #303030;
  opacity: 8%;
}
</style>
