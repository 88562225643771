<template>
  <v-footer app class="text-caption" :style="footer">
    <!-- Larger footer -->
    <span class="d-none d-sm-block">
      {{ $appName }} &copy; {{ new Date().getFullYear() }} {{ $appVersion }}
      <br />
      Crafted in italy, perfected for <b>you</b>, developed in Vietnam

      <v-btn
        variant="tonal"
        href="https://umbertogiacobbi.biz/"
        target="_blank"
        size="x-small"
        >ug.biz</v-btn
      >
    </span>
    <!-- Tiny footer for mobile -->
    <span class="d-sm-none">
      {{ $appName }} &copy; {{ new Date().getFullYear() }} {{ $appVersion }}
      <br /><v-btn
        variant="tonal"
        size="x-small"
        href="https://umbertogiacobbi.biz/"
        target="_blank"
        >ug.biz</v-btn
      >
    </span>

    <div class="ml-auto text-right" v-if="imageCredits">
      Image creadits:<br />{{ props.imageCredits }}
    </div>
  </v-footer>
</template>

<script setup>
import { onMounted, defineProps, computed } from "vue";
import { useTheme } from "vuetify";

const theme = useTheme();

const props = defineProps({
  imageCredits: {
    type: String,
    required: false,
    default: "",
  },
});

const footer = computed(() => {
  return {
    backgroundColor:
      theme.name.value == "dark"
        ? "rgba(0, 0, 0, 0.3)"
        : "rgba(255, 255, 255, 0.8)",
    backdropFilter: "blur(16px)",
  };
});

// life cycle Hooks
onMounted(() => {});
</script>

<style scoped></style>
