<template>
  <v-container>
    <v-row>
      <v-col cols="6" class="d-flex flex-column">
        <v-text-field
          v-model="search"
          prepend-icon="mdi-magnify"
          label="Search"
          variant="plain"
          width="300"
          rounded
          clearable
          hint="Search by name, tag, or metadata"
        ></v-text-field>
      </v-col>
      <v-col cols="6" class="d-flex justify-end">
        <v-btn
          variant="plain"
          @click="toggleSortOrder"
          v-if="viewMode === 'grid'"
        >
          <v-icon>{{
            sortOrder === "asc" ? "mdi-sort-ascending" : "mdi-sort-descending"
          }}</v-icon>
        </v-btn>
        <v-btn variant="plain" @click="toggleViewMode">
          <v-icon>{{
            viewMode === "list" ? "mdi-view-grid" : "mdi-view-list"
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { defineModel } from "vue";

const search = defineModel("search", {
  type: String,
});

const sortOrder = defineModel("sortOrder", {
  type: String,
  default: "asc",
});

const viewMode = defineModel("viewMode", {
  type: String,
  default: "grid",
});

const toggleSortOrder = () => {
  sortOrder.value = sortOrder.value === "asc" ? "desc" : "asc";
};

const toggleViewMode = () => {
  viewMode.value = viewMode.value === "grid" ? "list" : "grid";
};
</script>

<style scoped>
/* Empty style */
</style>
