<template>
  <v-expansion-panels
    v-if="showSelectionTipsBanner && selectionMode"
    style="padding: 2rem"
  >
    <v-expansion-panel variant="popout">
      <v-expansion-panel-title disable-icon-rotate>
        Psst! We've got some tips for the selection mode. Click here to view
        them.
        <template v-slot:actions>
          <v-btn @click="closeBanner" icon="mdi-close" size="24" />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text>
        <!-- Content of the banner -->
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ac dui
        sit amet sapien interdum aliquam. Integer condimentum eget libero nec
        finibus.
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>

  <PleaseWait
    style="
      position: absolute;
      z-index: 10000;
      bottom: 0;
      transform: translate(-10%, 0%);
    "
  ></PleaseWait>

  <ContainerBrowserHead
    v-model:viewMode="viewMode"
    v-model:sortOrder="sortOrder"
    v-model:search="search"
  ></ContainerBrowserHead>

  <!-- Container for when we have no items -->
  <v-container
    class="d-flex align-center justify-center no-select"
    v-if="files.length === 0"
  >
    <v-empty-state
      image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
      text="This page is a blank canvas waiting for your creativity."
      title="No Photos Yet? Let’s Change That!"
    >
    </v-empty-state>
  </v-container>

  <!-- Real container of the items -->
  <v-container
    v-if="files.length > 0 && viewMode === 'grid'"
    class="d-flex align-content-start flex-wrap no-select justify-center"
    @click.self="handleEmptyAreaClick"
  >
    <template v-for="item in files" :key="item.id">
      <template v-if="item.type === 'File'"
        ><FileTile
          :canBeSelected="selectionMode"
          :canBeStarred="allowStarring"
          :canBeDeleted="allowDelete"
          :canBeDownloaded="allowDownload"
          :item="item"
          :selected="selectedItems.some((i) => i.id === item.id)"
          :useDynamicSizing="useDynamicSizing"
          :starred="isStarred(item.block)"
          @click="handleItemClick($event, item)"
          @star="handleStarClick(item)"
          @delete="handleDeleteClick(item)"
          @download="handleDownloadClick(item)"
        ></FileTile
      ></template>
      <template v-else-if="item.type === 'Folder'">
        <FolderTile
          :canBeSelected="selectionMode"
          :item="item"
          :selected="selectedItems.some((i) => i.id === item.id)"
          :useDynamicSizing="useDynamicSizing"
          @click="handleItemClick($event, item)"
          @star="handleStarClick(item)"
          @delete="handleDeleteClick(item)"
          @download="handleDownloadClick(item)"
        ></FolderTile>
      </template>
    </template>

    <v-container class="text-caption">
      {{ files.length }} files, {{ calculateTotalSize(files) }} data retrieved
      on {{ getCurrentDateTime() }}
    </v-container>
  </v-container>
  <!-- And the container for the list view -->
  <v-container
    v-if="files.length > 0 && viewMode === 'list'"
    @click.self="handleEmptyAreaClick"
  >
    <v-data-table
      v-model="selectedItems"
      v-model:search="search"
      :items="files"
      :headers="headers"
      item-key="id"
      return-object
      :show-select="selectionMode"
    >
      <template v-slot:item.id="{ item }">
        <v-container class="d-flex pa-0">
          <v-img
            :src="imageUrl(item.path, 300)"
            :srcset="generateImageSrcSet(item.path)"
            :sizes="generateImageSizes()"
            height="200"
            width="200"
            :alt="item.name"
            cover
            @click="handleItemClick($event, item)"
            style="cursor: hand"
          ></v-img>
          <v-container class="d-flex flex-column">
            <span>{{ item.name }}</span>
            <span class="text-caption"
              >Original name: {{ item.originalName }}</span
            >
            <v-textarea
              v-if="allowDescriptionChanges"
              label="Description"
              hint="A free description for your file, visible to the end users"
              v-model="item.description"
              required
              :counter="400"
              @update:focused="
                (focused) => updateDescriptionOnFocusOut(item, focused)
              "
              variant="plain"
              class="my-2"
            ></v-textarea>
            <span v-else class="text-caption">{{ item.description }}</span>
          </v-container>
        </v-container>
      </template>
      <template v-slot:item.stars="{ item }">
        <span
          :style="{
            color: getStarCount(item.block) === 0 ? 'silver' : 'inherit',
          }"
        >
          {{ getStarCount(item.block) }}
        </span>
      </template>
    </v-data-table>
  </v-container>

  <SelectionProperties
    ref="selectionProperties"
    id="selection-properties"
    :items="selectedItems"
    :selectedItem="selectedItem"
    :containerName="containerName"
  ></SelectionProperties>
</template>

<script setup>
import {
  ref,
  watch,
  computed,
  defineEmits,
  defineProps,
  defineModel,
  onMounted,
  getCurrentInstance,
} from "vue";
import ContainerBrowserHead from "@/components/ContainerBrowserHead";
import PleaseWait from "@/components/PleaseWait";

import FileTile from "@/components/tiles/FileTile";
import FolderTile from "@/components/tiles/FolderTile";
import SelectionProperties from "@/components/SidePanels/SelectionProperties";
import { updateFileDescription } from "@/api/file.client";
import { imageUrl } from "@/api/image.client";
import {
  generateImageSizes,
  generateImageSrcSet,
} from "@/helpers/image.helper";
import { calculateTotalSize } from "@/plugins/utils";

const headers = [
  { title: "Picture", key: "id", sortable: true, align: "left" },
  { title: "Tags", key: "tags", sortable: false, align: "left" },
  { title: "Size", key: "size", value: "sizeAsString", align: "end" },
  { title: "Stars", key: "stars", sortable: false, align: "end" },
];

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const viewMode = ref("grid");
const sortOrder = ref("asc");
const search = ref("");
const originalImageDescription = ref(null);

const files = defineModel("files", {
  type: Array,
  default: [],
});

const originalFiles = ref([]);

watch(files, (newVal) => {
  // If the query is empty, we want to keep the original files, if not fuck
  originalFiles.value = [...newVal];
});

const selectedItems = defineModel("selectedItems", {
  type: Array,
  default: [],
});

const selectedItem = computed(() => {
  return selectedItems.value.length > 0
    ? selectedItems.value[selectedItems.value.length - 1]
    : null;
});

const emit = defineEmits([
  "selectionChanged",
  "itemClick",
  "star",
  "delete",
  "download",
  "selectionBannerClosed",
]);

// eslint-disable-next-line no-unused-vars
const props = defineProps({
  selectionMode: {
    type: Boolean,
    default: false,
  },
  showSelectionTipsBanner: {
    type: Boolean,
    default: false,
  },
  allowStarring: {
    type: Boolean,
    default: true,
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  allowDownload: {
    type: Boolean,
    default: true,
  },
  allowDescriptionChanges: {
    type: Boolean,
    default: true,
  },
  useDynamicSizing: {
    type: Boolean,
    required: false,
    default: false,
  },
  files: {
    type: Array,
    required: true,
  },
  stars: {
    type: Array,
    required: true,
  },
  containerName: {
    type: String,
    default: "",
  },
});

// Watch for changes in the props
watch(
  () => props.selectionMode,
  (newVal) => {
    // Execute your code here
    if (newVal) {
      // Code to execute when selectionMode is true
    } else {
      selectedItems.value = [];
    }
  },
);

// eslint-disable-next-line no-unused-vars
watch(viewMode, (newVal, oldVal) => {
  console.log("View mode changed", viewMode.value);

  if (newVal !== "grid" && newVal !== "list") {
    console.error(
      `Invalid viewMode: ${newVal}. It must be either "grid" or "list".`,
    );
    return;
  }
});

// eslint-disable-next-line no-unused-vars
watch(sortOrder, (newVal, oldVal) => {
  console.log("Sort order changed", sortOrder.value);

  if (newVal !== "asc" && newVal !== "desc") {
    console.error(
      `Invalid sortOrder: ${newVal}. It must be either "asc" or "desc".`,
    );
    return;
  }

  files.value.sort((a, b) => {
    if (newVal === "asc") {
      return a.name.localeCompare(b.name);
    } else {
      return b.name.localeCompare(a.name);
    }
  });
});

// eslint-disable-next-line no-unused-vars
watch(search, (newVal, oldVal) => {
  console.log("Search query changed", search.value);

  if (newVal === "") {
    files.value = [...originalFiles.value];
    return;
  }

  const query = newVal.toLowerCase();
  files.value = originalFiles.value.filter((item) => {
    return item.name.toLowerCase().includes(query);
    //item.tags.some((tag) => tag.toLowerCase().includes(query))
  });
});

// Controls refs
const selectionProperties = ref(null);

// life cycle Hooks
onMounted(() => {});

const handleStarClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Star clicked", item);
  }

  emit("star", item);
};

const handleDeleteClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Delete clicked", item);
  }

  emit("delete", item);
};

const handleDownloadClick = (item) => {
  if (process.env.NODE_ENV === "development") {
    console.debug("Download clicked", item);
  }

  emit("download", item);
};

// Event handlers
const handleItemClick = (event, item) => {
  if (!props.selectionMode) {
    // We're not in selection mode, emit the itemClick event
    emit("itemClick", event, item);
    return;
  }

  if (event.shiftKey) {
    handleShiftClick(item);
  } else if (event.ctrlKey || event.metaKey) {
    handleCtrlClick(item);
  } else {
    handleRegularClick(item);
  }
};

const updateDescriptionOnFocusOut = async (item, isFocused) => {
  if (!isFocused) {
    try {
      await updateFileDescription(item.id, item.description);
    } catch (ex) {
      item.description = originalImageDescription.value;
      console.error("Error on update file description: ", ex);
      global.$error("An error occurred while updating the file description.");
    }
  } else {
    originalImageDescription.value = item.description;
  }
};

const isStarred = (blockId) => {
  if (props.stars.length > 0) {
    return props.stars.find((s) => s.starredBlock === blockId) !== undefined;
  }
  return false;
};

const closeBanner = (event) => {
  event.stopPropagation();
  emit("selectionBannerClosed");
};

const handleEmptyAreaClick = () => {
  console.debug("Empty area clicked");
  selectedItems.value = [];
  emit("selectionChanged", selectedItems.value);
};

const handleShiftClick = (item) => {
  let newItems = [];
  const finalItems = [...selectedItems.value];
  if (selectedItems.value.length === 0) {
    newItems.push(item);
  } else {
    const lastSelectedItem = finalItems[finalItems.length - 1];
    const startIndex = props.files.findIndex(
      (i) => i.id === lastSelectedItem.id,
    );
    const endIndex = props.files.findIndex((i) => i.id === item.id);
    const range = [startIndex, endIndex].sort((a, b) => a - b);
    newItems = props.files.slice(range[0], range[1] + 1);
  }

  for (const element of newItems) {
    if (finalItems.findIndex((item) => item.id === element.id) === -1) {
      finalItems.push(element);
    }
  }

  selectedItems.value = finalItems;
  emit("selectionChanged", selectedItems.value);
};

const handleCtrlClick = (item) => {
  const tempItems = [...selectedItems.value];
  const existingIndex = tempItems.findIndex((i) => i.id === item.id);
  if (existingIndex !== -1) {
    tempItems.splice(existingIndex, 1);
  } else {
    tempItems.push(item);
  }
  selectedItems.value = tempItems;
  emit("selectionChanged", selectedItems.value);
};

const handleRegularClick = (item) => {
  if (selectedItems.value.length === 1) {
    if (selectedItems.value[0].id === item.id) {
      selectedItems.value = [];
    } else {
      selectedItems.value = [item];
    }
  } else {
    selectedItems.value = [item];
  }
  emit("selectionChanged", selectedItems.value);
};

const getStarCount = (blockId) => {
  return props.stars.filter((star) => star.starredBlock === blockId).length;
};

const getCurrentDateTime = () => {
  return new Date().toLocaleString(undefined, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
  });
};
</script>
