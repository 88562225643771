import { createI18n } from "vue-i18n";
import en from "./locales/en.json";
import vi from "./locales/vi.json";
import fr from "./locales/fr.json";

const english = "en";
const vietnamese = "vi";
const french = "fr";

const localizationStrings = {
  [english]: en,
  [vietnamese]: vi,
  [french]: fr,
};

export const configureI18n = () => {
  const i18n = createI18n({
    locale: english,
    fallbackLocale: english,
    messages: localizationStrings,
  });

  return i18n;
};
