export function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  if (bytes === 0) return "0 Byte";
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
}

export function getGreetings() {
  let date = new Date();
  let currentHour = date.getHours();
  if (currentHour >= 3 && currentHour < 12) {
    return "Rise and shine! Good morning!";
  } else if (currentHour >= 12 && currentHour < 15) {
    return "Afternoon, sunshine! How's your day going?";
  } else if (currentHour >= 15 && currentHour < 20) {
    return "Good evening! Time to wind down.";
  } else if (currentHour >= 20 && currentHour < 3) {
    return "Time for some rest. Sweet dreams!";
  } else {
    return "Hello there!";
  }
}

export function formatBytes(bytes) {
  return bytesToSize(bytes);
}

export function calculateTotalSize(files) {
  const totalSize = files.reduce((acc, file) => acc + file.size, 0);
  return bytesToSize(totalSize);
}
