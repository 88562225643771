<template>
  <BaseTile showColorAccent ref="baseTile">
    <template #preview>
      <v-img class="ma-8" src="@/assets/images/icons/folder.svg"></v-img>
    </template>
  </BaseTile>
</template>

<script setup>
import { ref } from "vue";
import BaseTile from "./BaseTile.vue";

const baseTile = ref(null);
</script>

<style scoped>
/* Add any additional styles here */
</style>
