import {
  configureAuthInterceptors,
  configureRequestOriginInterceptors,
} from "./config";
import { useAuthStore } from "@/stores/auth.store";
import { workspaceInstance } from "./workspace.client";
import { containerInstance } from "./container.client";
import { colorInstance } from "./color.client";
import { shareInstance } from "./share.client";
import { tagInstance } from "./tag.client";
import { blocksInstance } from "./block.client";
import { imageInstance } from "./image.client";
import { authInstance } from "./auth.client";
import { fileInstance } from "./file.client";
import { salesInstance } from "./sales.client";
import { interactionInstance } from "./interaction.client";
import { ref } from "vue";

export const isLoading = ref(false);

const configureLoadingInterceptors = (instance) => {
  instance.interceptors.request.use(
    (config) => {
      isLoading.value = true;
      return config;
    },
    (error) => {
      isLoading.value = false;
      return Promise.reject(error);
    },
  );

  instance.interceptors.response.use(
    (response) => {
      isLoading.value = false;
      return response;
    },
    (error) => {
      isLoading.value = false;
      return Promise.reject(error);
    },
  );
};

export const configureAxiosInstances = () => {
  configureRequestOriginInterceptors(authInstance);

  const authStore = useAuthStore();
  configureAuthInterceptors(authStore, workspaceInstance);
  configureAuthInterceptors(authStore, containerInstance);
  configureAuthInterceptors(authStore, colorInstance);
  configureAuthInterceptors(authStore, shareInstance);
  configureAuthInterceptors(authStore, tagInstance);
  configureAuthInterceptors(authStore, blocksInstance);
  configureAuthInterceptors(authStore, imageInstance);
  configureAuthInterceptors(authStore, fileInstance);
  configureAuthInterceptors(authStore, interactionInstance);
  configureAuthInterceptors(authStore, salesInstance);

  // Configure loading interceptors
  configureLoadingInterceptors(workspaceInstance);
  configureLoadingInterceptors(containerInstance);
  configureLoadingInterceptors(colorInstance);
  configureLoadingInterceptors(shareInstance);
  configureLoadingInterceptors(tagInstance);
  configureLoadingInterceptors(blocksInstance);
  configureLoadingInterceptors(imageInstance);
  configureLoadingInterceptors(fileInstance);
  configureLoadingInterceptors(interactionInstance);
  configureLoadingInterceptors(salesInstance);
};
