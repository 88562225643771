import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

const baseURL =
  process.env.NODE_ENV === "production" || $useRemoteApi
    ? "https://filevault.graybay-4c50acea.northeurope.azurecontainerapps.io/"
    : "https://localhost:7922/";

export const imageInstance = axios.create({
  baseURL: baseURL,
});

export const uploadImages = async (containerId, images) => {
  let formData = new FormData();
  formData.append("containerId", containerId);
  images.forEach((image) => {
    formData.append("images", image);
  });

  return await imageInstance.post("image", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const deleteImage = async (imageId) => {
  return await imageInstance.delete(`image/${imageId}`);
};

export const deleteImages = async (imageIds) => {
  return await imageInstance.post("image/delete", imageIds);
};

export const getImage = async (imagePath) => {
  if (isInt(imagePath)) {
    return await imageInstance.get(`image/${imagePath}`, {
      responseType: "blob",
    });
  } else {
    const path = encodeURIComponent(imagePath);
    return await imageInstance.get(`image?filePath=${path}`, {
      responseType: "blob",
    });
  }
};

export const getArchive = async (imagePathes, containerName) => {
  const model = {
    pathes: imagePathes,
    albumName: containerName,
  };

  return await imageInstance.post("image/archive", model, {
    responseType: "blob",
  });
};

export const imageUrl = (imagePath, width = 0) => {
  const setWidth = width > 0 ? `?width=${width}&` : "?";

  if (isInt(imagePath)) {
    return `${baseURL}image/${imagePath}${setWidth}`;
  } else {
    const path = encodeURIComponent(imagePath);
    return `${baseURL}image${setWidth}filePath=${path}`;
  }
};

const isInt = (value) => {
  let x;
  if (isNaN(value)) {
    return false;
  }
  x = parseFloat(value);
  return (x | 0) === x;
};
