import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

// This service offer different methods to interact with the blocks API including
// their metadata. The metadata is a key-value store that can be used to store
// any arbitrary data

export const blocksInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getBlock = async (blockId) => {
  return await blocksInstance.get(`/blocks/${blockId}`);
};

export const getAllBlockMetadata = async (blockId) => {
  return await blocksInstance.get(`/blocks/${blockId}/metadata`);
};

export const setBlockMetadata = async (blockId, category, keyName, value) => {
  return await blocksInstance.put(
    `/blocks/${blockId}/metadata/${category}/${keyName}`,
    JSON.stringify(value),
    {
      headers: {
        "Content-Type": "application/json",
      },
    },
  );
};

export const getBlockMetadataKey = async (blockId, category, keyName) => {
  // eslint-disable-next-line prettier/prettier
  return await blocksInstance.get(`/blocks/${blockId}/metadata/${category}/${keyName}`);  
};

export const getBlockMetadataValue = async (blockId, category, keyName) => {
  // eslint-disable-next-line prettier/prettier
  return await blocksInstance.get(`/blocks/${blockId}/metadata/${category}/${keyName}/value`);  
};
