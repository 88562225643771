<template>
  <v-alert
    class="breakpoint-label"
    type="info"
    v-if="showCurrentBreakpoint"
    closable
    density="compact"
  >
    Breakpoint: {{ currentBreakpoint.toUpperCase() }}
  </v-alert>
  <v-alert
    class="breakpoint-label-mobile"
    type="info"
    v-if="xs || sm"
    closable
    density="compact"
  >
    At current stage, Mobile support is limited we strongly suggest you to use a
    tablet or desktop for the best experience.
  </v-alert>
  <ErrorBoundary>
    <v-layout class="rounded rounded-md">
      <router-view />
    </v-layout>
  </ErrorBoundary>
</template>

<script setup>
import ErrorBoundary from "@/components/ErrorBoundary.vue";
import { computed, ref, onMounted } from "vue";
import { useDisplay } from "vuetify";
import { useTheme } from "vuetify";
// Global properties
import { $showCurrentBreakpoint } from "@/plugins/globalProperties";

const { name, xs, sm } = useDisplay();
const theme = useTheme();

// Local reactive properties
const showCurrentBreakpoint = ref($showCurrentBreakpoint);

const currentBreakpoint = computed(() => {
  return name.value;
});

onMounted(() => {
  // set the app theme according to the stored values on startup
  const userSelectedTheme = localStorage.getItem("theme");
  const systemTheme = localStorage.getItem("use-system-theme");

  if (systemTheme && systemTheme === "true") {
    const updatedTheme = window.matchMedia("(prefers-color-scheme: dark)")
      .matches
      ? "dark"
      : "light";
    theme.global.name.value = updatedTheme;
  } else if (userSelectedTheme) {
    if (userSelectedTheme !== theme.global.name.value) {
      theme.global.name.value = userSelectedTheme;
    }
  }
});
</script>

<style>
.no-select {
  user-select: none;
}
</style>

<style scoped>
.breakpoint-label {
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%);
  opacity: 0.8;
}
.breakpoint-label-mobile {
  position: absolute;
  top: 8px;
  left: 50%;
  z-index: 9999;
  transform: translateX(-50%);
  opacity: 0.8;
  width: 80%;
}
</style>
