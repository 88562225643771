import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const tagInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getTags = async () => {
  return await tagInstance.get("/tag/all");
};

export const getTag = async (tagId) => {
  return await tagInstance.get(`/tag/${tagId}`);
};

export const getFileTags = async (fileIds) => {
  return await tagInstance.post("/tag/file-tags", fileIds);
};

export const addTag = async (tag) => {
  return await tagInstance.post("/tag", tag);
};

export const updateTag = async (tag) => {
  return await tagInstance.put("/tag", tag);
};

export const deleteTag = async (tagId) => {
  return await tagInstance.delete(`/tag/${tagId}`);
};
