import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const colorInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getTagColors = async () => {
  return await colorInstance.get("/color/tag");
};

export const getContainerColors = async () => {
  return await colorInstance.get("/color/container");
};

export const getWorkspaceColors = async () => {
  return await colorInstance.get("/color/workspace");
};
