export const configureAuthInterceptors = (authStore, axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const token = authStore.token;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });
};

export const configureRequestOriginInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use((config) => {
    const { origin } = window.location;
    config.headers["x-origin-url"] = origin;

    return config;
  });
};
