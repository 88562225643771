import { setState } from "./state";
import { loadGoogleSdk } from "./utils";
import GoogleLogin from "./GoogleLogin.vue";

export default {
  install: (app, options) => {
    options && setState(options);

    loadGoogleSdk().then(() => {
      const idConfiguration = {
        client_id: options.clientId,
        auto_select: options.autoLogin === true,
        callback: options.callback,
        use_fedcm_for_prompt: true,
        ...options.idConfiguration,
      };

      window.google.accounts.id.initialize(idConfiguration);
      options.prompt && window.google.accounts.id.prompt();
    });

    app.component("GoogleLogin", GoogleLogin);
  },
};
