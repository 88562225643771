<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <v-container
      class="pb-0"
      :class="{
        'login-card-dark': theme.global.name.value === 'dark',
        'login-card-light': theme.global.name.value === 'light',
      }"
    >
      <template v-if="containerShareFound">
        <!-- Share details row -->
        <v-row dense>
          <v-col cols="12">
            <v-container class="d-flex flex-column align-center justify-center">
              <v-avatar :image="containerOwnerAvatar" size="80"></v-avatar>
              <p class="ma-4">
                <b>{{ containerOwner.name }} {{ containerOwner.familyName }}</b>
                shared {{ containerShare.name }}
              </p>
              <p class="text-center text-caption">
                {{ containerShare.description }}
              </p>
            </v-container>
          </v-col>
        </v-row>

        <!-- A pin is requested to access -->
        <v-row dense v-if="showPin">
          <v-col
            cols="12"
            class="d-flex alight-center justify-center flex-column"
          >
            <p class="text-center">
              Please enter the pin provided by the author to proceed.
            </p>
            <v-otp-input
              v-model="otpInput"
              :length="8"
              :autofocus="true"
              variant="solo-filled"
            ></v-otp-input>
          </v-col>
        </v-row>

        <!-- Login row -->
        <v-row dense v-if="showLogin && !hasErrors">
          <v-col cols="12" class="d-flex align-center justify-center">
            <template v-if="authStore.hasToken">
              <!-- Show a simple login button the user already has a token -->
              <v-btn rounded="xl" elevation="4" @click="googleLogin">
                <template v-slot:prepend>
                  <v-icon>mdi-google</v-icon>
                </template>
                Continue with google</v-btn
              >
            </template>
            <template v-else>
              <!-- Here we need to identify the user -->
              <GoogleLogin
                :callback="loginCallback"
                :error="loginError"
                popup-type="CODE"
              >
                <v-btn rounded="xl" elevation="4">
                  <template v-slot:prepend>
                    <v-icon>mdi-google</v-icon>
                  </template>
                  Login with google</v-btn
                >
              </GoogleLogin>
              &nbsp;
              <v-btn
                rounded="xl"
                elevation="4"
                @click="anonumousLogin"
                v-if="containerShare.allowAnonymousAccess"
              >
                <template v-slot:prepend>
                  <v-icon>mdi-incognito</v-icon>
                </template>
                View Anonymously</v-btn
              >
            </template>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" class="d-flex align-center justify-center">
            There was and usthentication issue. Try to reload the page or
            contact the link creator.
          </v-col>
        </v-row>

        <!-- Countdown row -->
        <v-row dense v-if="showCountdown">
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              Hold on, the album will be available in:
            </p>
            <Countdown
              mainColor="silver"
              secondFlipColor="silver"
              :deadline="getDeadline()"
            />
          </v-col>
        </v-row>
      </template>
      <template v-if="!loading && !containerShareFound">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <v-empty-state
              action-text="Go back to homepage"
              @click:action="goBackToHomepage"
              image="https://vuetifyjs.b-cdn.net/docs/images/components/v-empty-state/astro-dog.svg"
              text="The link you provided is expired or does not exist. Please check the link and try again."
              title="No link to show here."
            ></v-empty-state>
          </v-col>
        </v-row>
      </template>

      <!-- Loading row -->
      <template v-if="loading">
        <v-row>
          <v-col
            cols="12"
            class="d-flex flex-column align-center justify-center"
          >
            <p class="text-center text-overline">
              Hang tight! Retrieving share details, please hold on...
            </p>
            <v-progress-circular
              class="my-8"
              :size="70"
              :width="7"
              color="green"
              indeterminate
            ></v-progress-circular>
          </v-col>
        </v-row>
      </template>

      <!-- Footer row -->
      <v-row dense>
        <v-col cols="12" class="d-flex flex-column">
          <AppLogo class="pl-0 pb-0"></AppLogo>
          <p class="text-overline">Crafted by Pros, Perfected for You</p>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script setup>
import { getCurrentInstance, nextTick, ref, onMounted } from "vue";
// https://github.com/coskuncay/vue3-flip-countdown
import { Countdown } from "vue3-flip-countdown";
import { getShare } from "@/api/share.client";
import {
  getContainerOwner,
  getContainerOwnerAvatar,
} from "@/api/container.client";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useTheme } from "vuetify";
import { useAuthStore } from "@/stores/auth.store";
import moment from "moment";
import AppLogo from "@/components/AppLogo.vue";

const theme = useTheme();
// eslint-disable-next-line no-unused-vars
const { t } = useI18n();
const route = useRoute();
const authStore = useAuthStore();

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

// Local reactive state
const containerShare = ref({});
const containerShareFound = ref(false);
const loading = ref(true);
const showCountdown = ref(false);
const showLogin = ref(false);
const showPin = ref(false);
const containerOwnerAvatar = ref("");
const containerOwner = ref({});
const hasErrors = ref(false);

const otpInput = ref("");

onMounted(async () => {
  console.debug("Collection view langing page mounted");

  const hmac = route.query.hmac;
  console.info("HMAC:", hmac);
  const shareId = route.params.id;
  console.info("Share ID:", shareId);

  if (process.env.NODE_ENV === "development") {
    setTimeout(function () {
      loadShare(shareId);
    }, 1000); // 1000 milliseconds = 1 second
  } else {
    loadShare(shareId);
  }
});

const loadShare = async (shareId) => {
  console.info("Loading share...");

  getShare(shareId)
    .then((response) => {
      containerShare.value = response.data;
      containerShareFound.value = true;
      console.debug(
        "Share found, performing secutiry checks",
        containerShare.value,
      );

      if (containerShare.value.allowAnonymousAccess) {
        console.debug("Anonymous access is allowed");
      } else {
        console.debug("Anonymous access is not allowed");
      }

      // Is this share retired or disabled?
      if (!containerShare.value.enabled || containerShare.value.retired) {
        containerShareFound.value = false;
        return;
      }

      getContainerOwner(containerShare.value.container).then((response) => {
        containerOwner.value = response.data;
        console.debug("Container owner", containerOwner.value);
      });

      getContainerOwnerAvatar(containerShare.value.container).then(
        (response) => {
          containerOwnerAvatar.value = response.data;
          console.debug("Container owner avatar", containerOwnerAvatar.value);
        },
      );

      // Now do some checks to see if we need to show the countdown
      if (containerShare.value.sunriseDate !== null) {
        if (new Date(containerShare.value.sunriseDate) > new Date()) {
          showCountdown.value = true;
          // All done return
          return;
        }
      }

      if (containerShare.value.sunriseDate !== null) {
        if (new Date(containerShare.value.sunriseDate) < new Date()) {
          // We can't show an expired album
          containerShareFound.value = false;
          return;
        }
      }

      // Here we can show the link! Yay\o/

      // In any case show the login buttons
      showLogin.value = true;

      if (containerShare.value.pin.length === 0) {
        console.debug("No pin required");
      } else {
        console.info("Pin required Enabled the pin input");
        showPin.value = true;
      }
    })
    .catch((error) => {
      console.error("Error fetching the share:", error);
      throw error;
    })
    .finally(() => {
      loading.value = false;
    });
};

function checkPin(pin) {
  console.info("Checking pin...");

  const result = pin === containerShare.value.pin;

  if (result) {
    console.debug("Pin is correct");
  } else {
    console.debug("Pin is incorrect");
    global.$warn("Incorrect pin, please try again.");

    otpInput.value = "";
  }

  return result;
}

function getDeadline() {
  return moment(String(containerShare.value.sunriseDate)).format(
    "YYYY-MM-DD HH:mm:ss",
  );
}

function anonumousLogin() {
  console.log("Anonymous login");
  if (!showPin.value) {
    console.debug("No pin required, redirecting to collection viewer");
    gotoViewer();
  } else {
    console.info("Pin is required to view this collection");
  }

  if (showPin.value && checkPin(otpInput.value)) {
    console.debug("Pin is correct, redirecting to collection viewer");

    gotoViewer();
  } else {
    if (process.env.NODE_ENV === "development") {
      console.warn("Pin is incorrect");
    }
  }
}

async function googleLogin() {
  console.log("Google login");

  // Note: the checkPin method will show a warning if the pin is incorrect
  if (showPin.value && checkPin(otpInput.value)) {
    console.info("Pin is correct");

    if (authStore.hasToken) {
      // Is registered so go ahead and redirect to the viewer. If not wait for the callback to redirect
      nextTick(() => {
        gotoViewer();
      });
    }
  } else if (showPin.value && !checkPin(otpInput.value)) {
    if (process.env.NODE_ENV === "development") {
      console.debug("Pin is incorrect");

      // Do nothing, just return
      return;
    }
  }

  // Here we're all set go ahead and redirect to the viewer
  gotoViewer();
}

async function loginCallback(value) {
  if (process.env.NODE_ENV === "development") {
    console.debug("Google login callback", value);
  }

  try {
    // Specify that the new user (if in the BE is a new user) will be a guest
    value.guest = true;

    await authStore.signInAsync(value);
  } catch (error) {
    if (error.isAxiosError && error.code === "ERR_NETWORK") {
      console.error("Network error occurred during sign-in:", error);
      hasErrors.value = true;
    } else {
      console.error("An error occurred during sign-in:", error);
      hasErrors.value = true;
    }
  }

  nextTick(() => {
    gotoViewer();
  });
}

function loginError(value) {
  hasErrors.value = true;
  console.error("Login error", value);
}

function gotoViewer() {
  // Redirect to collection viewer
  global.$router.push({
    name: "collectionviewer",
    params: { id: route.params.id },
    query: { hmac: route.query.hmac },
  });
}

const goBackToHomepage = () => {
  global.$router.push({ name: "home" });
};
</script>

<style scoped>
.login-card-light {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}

.login-card-dark {
  min-width: 400px;
  max-width: 600px;
  min-height: 300px;
  border: 1px solid rgba(255, 255, 255, 0.25);
  border-radius: 20px;
  background-color: rgba(24, 24, 24, 0.9);
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(20px);
}
</style>
