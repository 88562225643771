import { defineStore } from "pinia";
import { decodeJwt } from "jose";
import { refreshAsync, signInAsync } from "@/api/auth.client";

const localStorageKey = "auth-user";

const decodeJwtToken = (token) => {
  const decodedToken = decodeJwt(token, {
    complete: true,
    header: true,
  });

  if (process.env.NODE_ENV === "development") {
    console.log("Decoded token: ", decodedToken);
  }

  return decodedToken;
};

export const useAuthStore = defineStore({
  id: "auth",

  state: () => ({
    user: {},
    refreshTokenTimeout: null,
  }),

  actions: {
    signOut() {
      this.stopRefreshTokenTimer();
      this.user = {};
      localStorage.removeItem(localStorageKey);
    },

    async signInAsync(exchangeCodeModel) {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("SignIn");
        }

        const result = await signInAsync(exchangeCodeModel);

        if (process.env.NODE_ENV === "development") {
          console.log("Response data: ", result.data);
        }

        const token = result.data.token;
        const refreshToken = result.data.refreshToken;

        const decodedToken = decodeJwtToken(token);

        this.user = {
          token: token,
          refreshToken: refreshToken,
          email: decodedToken.email,
          name: decodedToken.name,
          picture: decodedToken.picture,
        };

        localStorage.setItem(localStorageKey, JSON.stringify(this.user));
        this.startRefreshTokenTimer();
      } catch (exception) {
        this.signOut();
        if (process.env.NODE_ENV === "development") {
          console.error(exception);
        }

        // rethrow the exception to be caught by the caller
        throw exception;
      }
    },

    async refreshTokenAsync() {
      try {
        if (process.env.NODE_ENV === "development") {
          console.log("RefreshToken");
        }

        const result = await refreshAsync({
          refreshToken: this.user.refreshToken,
        });

        if (process.env.NODE_ENV === "development") {
          console.log("Response data: ", result.data);
        }

        const token = result.data.token;
        const refreshToken = result.data.refreshToken;

        const decodedToken = decodeJwtToken(token);

        const user = {
          token: token,
          refreshToken: refreshToken,
          email: decodedToken.email,
          name: decodedToken.name ?? this.user.name,
          picture: decodedToken.picture ?? this.user.picture,
        };

        this.user = user;
        localStorage.setItem(localStorageKey, JSON.stringify(this.user));
        this.startRefreshTokenTimer();
      } catch (exception) {
        this.signOut();
        if (process.env.NODE_ENV === "development") {
          console.error(exception);
        }

        // rethrow the exception to be caught by the caller
        throw exception;
      }
    },

    startRefreshTokenTimer() {
      const decodedToken = decodeJwt(this.user.token, {
        complete: true,
        header: true,
      });

      // set a timeout to refresh the token 30 sec before it expires
      const expires = new Date(decodedToken.exp * 1000);
      const timeout = expires.getTime() - Date.now() - 30 * 1000;
      this.refreshTokenTimeout = setTimeout(this.refreshTokenAsync, timeout);

      if (process.env.NODE_ENV === "development") {
        console.debug("Refresh token in: ", timeout);
        console.debug("Refresh token at: ", expires);
      }
    },

    stopRefreshTokenTimer() {
      clearTimeout(this.refreshTokenTimeout);
    },

    loadDataFromLocalStorage() {
      const data = localStorage.getItem(localStorageKey);
      const user = JSON.parse(data);
      if (user.token && user.refreshToken) {
        this.user = user;
      }
    },
  },

  getters: {
    token(state) {
      return state.user?.token;
    },

    userName(state) {
      return state.user?.name;
    },

    userEmail(state) {
      return state.user?.email;
    },

    userPicture(state) {
      return state.user?.picture;
    },

    hasToken(state) {
      return !!state.user?.token;
    },

    hasRefreshToken(state) {
      return !!state.user?.refreshToken;
    },
  },
});
