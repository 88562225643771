import { watch } from "vue";
import config from "./config";
import { state, libraryState } from "./state";

export const mergeObjects = (obj1, obj2) => {
  const mergedObj = { ...obj1 };
  for (const key in obj2) {
    obj2[key] !== undefined &&
      obj2[key] !== null &&
      (mergedObj[key] = obj2[key]);
  }
  return mergedObj;
};

export const renderLoginButton = (
  idConfiguration,
  buttonRef,
  buttonConfig,
  hasSlot,
  error,
) => {
  const callback = idConfiguration.callback;

  idConfiguration.callback = (response) => {
    if (!response.credential) {
      error && error(error);
    } else {
      callback && callback(response);
    }
  };

  window.google.accounts.id.initialize(idConfiguration);

  const button = buttonRef.value;
  if (button) {
    !hasSlot && window.google.accounts.id.renderButton(button, buttonConfig);
  }
};

export const loadGoogleSdk = () =>
  new Promise((resolve, reject) => {
    if (!libraryState.apiLoadIntitited) {
      const script = document.createElement("script");
      libraryState.apiLoadIntitited = true;

      script.addEventListener("load", () => {
        libraryState.apiLoaded = true;
        resolve(window.google);
      });

      script.addEventListener("error", () => {
        reject("Failed to load the Google SDK.");
      });

      script.src = config.library;
      script.async = true;
      script.defer = true;

      document.head.appendChild(script);
    }
  });

export const googleSdkLoaded = (action) => {
  if (!libraryState.apiLoadIntitited) {
    loadGoogleSdk().then((google) => action(google));
  } else if (!libraryState.apiLoaded) {
    watch(
      () => libraryState.apiLoaded,
      (loaded) => {
        loaded && action(window.google);
      },
    );
  } else {
    action(window.google);
  }
};

export const googleTokenLogin = (options) => {
  return new Promise((resolve, reject) => {
    googleSdkLoaded((google) => {
      google.accounts.oauth2
        .initTokenClient({
          client_id: options?.clientId || state.clientId || "",
          scope: config.scopes,
          callback: (response) => {
            if (response.access_token) {
              resolve(response);
            } else {
              reject(response);
            }
          },
          error_callback: (response) => {
            reject(response);
          },
        })
        .requestAccessToken();
    });
  });
};

export const googleAuthCodeLogin = (options) => {
  return new Promise((resolve, reject) => {
    googleSdkLoaded((google) => {
      google.accounts.oauth2
        .initCodeClient({
          client_id: options?.clientId || state.clientId || "",
          scope: config.scopes,
          ux_mode: "popup",
          callback: (response) => {
            if (response.code) {
              resolve(response);
            } else {
              reject(response);
            }
          },
          error_callback: (response) => {
            reject(response);
          },
        })
        .requestCode();
    });
  });
};

export const googleLogout = () => {
  googleSdkLoaded((google) => {
    google.accounts.id.disableAutoSelect();
  });
};

export const onMount = (idConfiguration, buttonRef, options, hasSlot) => {
  googleSdkLoaded(() => {
    renderLoginButton(
      idConfiguration,
      buttonRef,
      options.buttonConfig,
      hasSlot,
      options.error,
    );
  });
};
