<template>
  <!-- Hero banner -->
  <v-container fluid class="landing-main_container">
    <section class="hero_section px-0">
      <v-sheet class="hero_section_content" theme="dark">
        <v-row>
          <v-col cols="12">
            <div class="two">
              <h1>
                Share your creations and get feedback, from anyone, anywhere!
              </h1>
              <h2 class="mb-8">
                with or without a
                <span class="text-red">{{ $appName }}</span> account
              </h2>
            </div>

            <p class="my-2">
              We're on a mission let you upload and share your photos with the
              yor customers and partners!
            </p>
            <p>
              Effortless Access, Total Control!
              <b
                >No sign-ups, no app downloads, credit cards - just a click or a
                QR scan.</b
              >
            </p>
            <p>
              Share your photos anywhere: web, mobile, desktop, press, email.
              You decide how, when and where to pitch!
            </p>
            <div class="hero_section_buttons">
              <div class="btn">
                <router-link :to="{ path: 'login', query: { signup: 'true' } }">
                  Sign in with Google
                </router-link>
              </div>
              <div class="btn">
                <router-link :to="{ path: 'login', query: { login: 'true' } }">
                  Watch a demo
                </router-link>
              </div>
            </div>
            <p class="hero_section_button_notes text-caption">
              * No credit card required.
            </p>
          </v-col>
        </v-row>
      </v-sheet>
    </section>
  </v-container>
</template>

<style scoped>
.landing-main_container {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding: 0;
}

.main_container::-webkit-scrollbar {
  display: none;
}

/* ===== Misc styles ===== */

section {
  scroll-snap-align: start;
}

h2 {
  font-weight: 300;
}

/* ===== Misc styles ===== */

/* ===== Hero Section Styles ===== */

.hero_section {
  scroll-snap-align: start;
  height: 100vh;
  width: 100hw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  position: relative;
  background-color: black;
}

.hero_section:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../assets/images/landing/heroBackground.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(4px);
  opacity: 0.5;
}

.hero_section_content {
  position: relative;
  padding: 0 8%;
  background-color: #00000001;
}

.hero_section_content p {
  color: white;
  font-size: 1.2rem;
  line-height: 1.9;
  filter: drop-shadow(1px 1px 0px rgba(0, 0, 0, 0.8));
}

.hero_section_buttons {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
}
.hero_section_button_notes {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  color: argb(255, 255, 255, 0.9) !important;
}
.hero_section_buttons .btn {
  position: relative;
  top: 0;
  left: 0;
  width: 250px;
  height: 50px;
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero_section_buttons .btn a {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.05);
  box-shadow: 0 15px 15px rgba(0, 0, 0, 0.3);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 30px;
  padding: 10px;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  color: #fff;
  z-index: 1;
  transition: 0.5s;
  backdrop-filter: blur(15px);
}

.hero_section_buttons .btn:hover a {
  letter-spacing: 3px;
}

.hero_section_buttons .btn a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255, 255, 255, 0.15), transparent);
  transform: skewX(45deg) translate(0);
  transition: 0.5s;
  filter: blur(0px);
}

.hero_section_buttons .btn:hover a::before {
  transform: skewX(45deg) translate(200px);
}

.hero_section_buttons .btn::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  bottom: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.hero_section_buttons .btn:hover::before
/*lightup button*/ {
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.hero_section_buttons .btn::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: -5px;
  width: 30px;
  height: 10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0.5;
}

.hero_section_buttons .btn:hover::after

/*lightup button*/ {
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
}

.hero_section_buttons .btn:nth-child(1)::before,
/*chnage 1*/
.hero_section_buttons .btn:nth-child(1)::after {
  background: #ff1f71;
  box-shadow:
    0 0 5px #ff1f71,
    0 0 15px #ff1f71,
    0 0 30px #ff1f71,
    0 0 60px #ff1f71;
}

.hero_section_buttons .btn:nth-child(2)::before,
/* 2*/
.hero_section_buttons .btn:nth-child(2)::after {
  background: #2db2ff;
  box-shadow:
    0 0 5px #2db2ff,
    0 0 15px #2db2ff,
    0 0 30px #2db2ff,
    0 0 60px #2db2ff;
}

.hero_section_buttons .btn:nth-child(3)::before,
/* 3*/
.hero_section_buttons .btn:nth-child(3)::after {
  background: #1eff45;
  box-shadow:
    0 0 5px #1eff45,
    0 0 15px #1eff45,
    0 0 30px #1eff45,
    0 0 60px #1eff45;
}

/* ===== Hero Section Styles end ===== */
</style>
