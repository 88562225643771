<script setup>
import { updateFileName, updateFileDescription, getStars } from "@/api/file.client";
import { getAllBlockMetadata } from "@/api/block.client";
import { getArchive, getImage, imageUrl } from "@/api/image.client";
import { saveAs } from "file-saver";
import {
  ref,
  watch,
  onMounted,
  defineModel,
  defineExpose,
  defineEmits,
  computed,
  getCurrentInstance,
} from "vue";
import { useI18n } from "vue-i18n";
import { useTheme } from "vuetify";

const theme = useTheme();

// eslint-disable-next-line no-unused-vars
const { t } = useI18n();

// Local reactive state
const chips = ref([
  "Programming",
  "Playing video games",
  "Watching movies",
  "Sleeping",
]);
const dbTags = ref(["Streaming", "Eating"]);
const drawerWidth = ref(300);
const drawer = ref(false);
const tab = ref("one");
const originalImageName = ref(null);
const originalImageDescription = ref(null);
const selectedItemMetadata = ref([]);
const selectedItemStars = ref([]);

// Get access at the this. objects
const { appContext } = getCurrentInstance();
const global = appContext.config.globalProperties;

const items = defineModel("items", {
  type: Array,
  default: [],
});

const selectedItem = defineModel("selectedItem", {
  type: Object,
  default: null,
});

watch(drawerWidth, (newValue) => {
  localStorage.setItem("selection-properties-drawer-width", newValue);
});

const props = defineProps({
  containerName: {
    type: String,
    default: "covers",
  },
});

// Watch for changes in items
watch(items, () => {
  if (selectedItem.value) {
    open();
  } else {
    close();
  }
});

// Watch for changes in selectedItem
watch(selectedItem, () => {
  if (selectedItem.value) {
    open();
    originalImageName.value = selectedItem.value.name;
    originalImageDescription.value = selectedItem.value.description;

    if (!selectedItem.value.id) {
      return;
    }

    getAllBlockMetadata(selectedItem.value.block)
      .then((result) => {
        selectedItemMetadata.value = result.data;
      })
      .catch((ex) => {
        console.error("Error loading the picture metadata.", ex);
        global.$error("Can't load the picture metadata.");
      });

    getStars(selectedItem.value.id)
      .then((result) => {
        selectedItemStars.value = result.data;
      })
      .catch((ex) => {
        console.error("Error loading the stars.", ex);
        global.$error("Can't load the picture likes.");
      });
  } else {
    close();
    originalImageName.value = null;
  }
});

// eslint-disable-next-line no-unused-vars
const emit = defineEmits(["opened", "closed"]);

const open = () => {
  drawer.value = true;
};

const close = () => {
  drawer.value = false;
};

const updateFileNameOnFocusOut = async (isFocused) => {
  if (!isFocused) {
    try {
      await updateFileName(selectedItem.value.id, selectedItem.value.name);
    } catch (ex) {
      selectedItem.value.name = originalImageName.value;
      console.error("Error on update file name: ", ex);
      global.$error("An error occurred while updating the file name.");
    }
  }
};

const updateDescriptionOnFocusOut = async (isFocused) => {
  if (!isFocused) {
    try {
      await updateFileDescription(
        selectedItem.value.id,
        selectedItem.value.description,
      );
    } catch (ex) {
      selectedItem.value.description = originalImageDescription.value;
      console.error("Error on update file description: ", ex);
      global.$error("An error occurred while updating the file description.");
    }
  }
};

// eslint-disable-next-line no-unused-vars
const downloadImage = async () => {
  try {
    const result = await getImage(selectedItem.value.id);
    saveAs(result.data, selectedItem.value.name);
  } catch (ex) {
    console.error("Error on downloading image: ", ex);
  }
};

// eslint-disable-next-line no-unused-vars
const downloadArchive = async () => {
  if (items.value.length > 0) {
    try {
      const result = await getArchive(
        items.value.map((i) => i.path),
        props.containerName,
      );
      saveAs(result.data, `${props.containerName}.zip`);
    } catch (ex) {
      console.error("Error on downloading archive: ", ex);
    }
  }
};

const groupedMetadata = computed(() => {
  if (!selectedItemMetadata.value) return {};
  return selectedItemMetadata.value.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = [];
    }
    acc[item.category].push(item);
    return acc;
  }, {});
});

const expandedCategories = ref({});

const toggleCategory = (category) => {
  expandedCategories.value[category] = !expandedCategories.value[category];
};

const isDarkTheme = ref(false);

watch(
  () => theme.name.value,
  (newVal) => {
    isDarkTheme.value = newVal === "dark";
  },
);

const toggleDrawerWidth = () => {
  drawerWidth.value = drawerWidth.value === 300 ? 500 : 300;
};

defineExpose({
  open,
  close,
});

onMounted(() => {
  const existingDrawerWidth = localStorage.getItem(
    "selection-properties-drawer-width",
  );
  if (existingDrawerWidth) {
    drawerWidth.value = existingDrawerWidth;
  }
});

const sortOrder = ref("asc");

const sortedStars = computed(() => {
  return [...selectedItemStars.value].sort((a, b) => {
    if (sortOrder.value === "asc") {
      return new Date(a.created) - new Date(b.created);
    } else {
      return new Date(b.created) - new Date(a.created);
    }
  });
});
</script>

<template>
  <v-navigation-drawer
    location="right"
    v-model="drawer"
    permanent
    :width="drawerWidth"
  >
    <v-container v-if="selectedItem" class="pa-0">
      <v-form fast-fail @submit.prevent class="px-2 py-4">
        <v-row align="center">
          <v-col cols="12">
            <v-img
              :src="imageUrl(selectedItem.path)"
              max-height="300"
              elevation="4"
            >
              <template v-slot:placeholder>
                <v-skeleton-loader type="image"></v-skeleton-loader>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Name"
              hint="Friendly name of the file"
              v-model="selectedItem.name"
              required
              :counter="50"
              @update:focused="updateFileNameOnFocusOut"
              variant="underlined"
            ></v-text-field>
            <v-textarea
              label="Description"
              hint="A free description for your file, visible to the end users"
              v-model="selectedItem.description"
              required
              :counter="400"
              @update:focused="updateDescriptionOnFocusOut"
              variant="underlined"
              class="my-2"
            ></v-textarea>
            <v-text-field
              v-model="selectedItem.originalName"
              label="File name"
              disabled
              hint="Name of the file when uploaded"
              variant="underlined"
            ></v-text-field>
            <v-combobox
              v-model="chips"
              :items="dbTags"
              label="Tags"
              variant="underlined"
              chips
              multiple
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :model-value="selected"
                  closable
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                  <span>(interest)</span>
                </v-chip>
              </template>
            </v-combobox>
          </v-col>
        </v-row>
        <v-row dense
          ><v-col cols="6">
            <v-text-field
              v-model="selectedItem.sizeAsString"
              label="Size"
              disabled
              variant="underlined"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="selectedItem.id"
              label="Picture code"
              disabled
              variant="underlined"
            ></v-text-field> </v-col
        ></v-row>

        <v-row dense>
          <v-col
            cols="12"
            class="d-flex align-center justify-center text-overline"
          >
            Download:
            <v-btn variant="plain" size="small" @click="downloadImage">
              <v-icon left>mdi-download</v-icon>
              picture
            </v-btn>
            <v-btn variant="plain" size="small" @click="downloadArchive">
              <v-icon left>mdi-archive</v-icon>
              archive
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-tabs v-model="tab">
        <v-tab value="one">Metadata</v-tab>
        <v-tab value="two">Likes</v-tab>
      </v-tabs>

      <v-tabs-window v-model="tab">
        <v-tabs-window-item value="one">
          <v-table density="compact">
            <tbody>
              <template
                v-for="(items, category) in groupedMetadata"
                :key="category"
              >
                <tr
                  :style="{
                    backgroundColor: isDarkTheme ? '#616161' : '#f5f5f5',
                    cursor: 'pointer',
                  }"
                  @click="toggleCategory(category)"
                >
                  <td colspan="2" class="text-left font-weight-bold">
                    <v-icon small>
                      {{
                        expandedCategories[category]
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                      }}
                    </v-icon>
                    {{ category }}
                  </td>
                </tr>
                <template v-if="expandedCategories[category]">
                  <tr
                    v-for="item in items"
                    :key="item.id"
                    :style="{
                      backgroundColor: isDarkTheme ? '#757575' : '#f9f9f9',
                    }"
                  >
                    <td class="text-caption">{{ item.keyName }}</td>
                    <td class="text-caption">
                      {{
                        item.keyValue.length > 200
                          ? item.keyValue.substring(0, 200) +
                            " ... value trimmed"
                          : item.keyValue
                      }}
                    </td>
                  </tr>
                </template>
              </template>
            </tbody>
          </v-table>
        </v-tabs-window-item>
        <v-tabs-window-item value="two">
          <v-data-table
            :headers="[
              { text: 'Created', value: 'created' },
              { text: 'Email', value: 'email' },
            ]"
            :items="sortedStars"
            item-value="id"
            density="compact"
          >
            <template v-slot:item.created="{ item }">
              {{
                new Date(item.created)
                  .toISOString()
                  .slice(0, 16)
                  .replace("T", " ")
              }}
            </template>
            <template v-slot:item.email="{ item }">
              {{ item.email }}
            </template>
          </v-data-table>
        </v-tabs-window-item>
      </v-tabs-window>
    </v-container>
    <template v-slot:append>
      <v-btn
        variant="plain"
        density="comfortable"
        icon
        @click="toggleDrawerWidth"
      >
        <v-icon>{{
          drawerWidth === 300 ? "mdi-arrow-expand" : "mdi-arrow-collapse"
        }}</v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>
