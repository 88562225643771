import { createRouter, createWebHistory } from "vue-router";

import LandingPage from "../views/LandingPage.vue";
import LoginView from "@/views/LoginView.vue";
import NotFound from "@/views/NotFound.vue";
import ServerError from "@/views/ServerError.vue";
import FullScreenPage from "@/views/FullScreenPage.vue";
import CollectionViewerLanding from "@/views/CollectionViewerLanding.vue";
import CollectionViewer from "@/views/CollectionViewer.vue";
import { useAuthStore } from "@/stores/auth.store";

const routes = [
  {
    path: "/",
    component: FullScreenPage,
    children: [
      {
        path: "/500",
        name: "ServerError",
        component: ServerError,
      },
      {
        path: "/:pathMatch(.*)*", // Catch-all route for 404
        name: "NotFound",
        component: NotFound,
      },
      {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: { requiresAuth: false, onlyAnonymous: true },
      },
      {
        path: "",
        name: "home",
        component: LandingPage,
      },
    ],
  },
  {
    path: "/workspace",
    name: "workspace",
    // Route level code-splitting this generates a separate chunk (workspace.[hash].js) for this route (will be the biggest chunk)
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "workspace" */ "../views/AppWorkspace.vue"),
    meta: { requiresAuth: true, onlyAnonymous: false },
  },
  {
    path: "/view/:id",
    component: FullScreenPage,
    children: [
      {
        path: "",
        name: "collectionviewerlanding",
        component: CollectionViewerLanding,
        meta: { requiresAuth: false, onlyAnonymous: false },
      },
      {
        path: "browser",
        name: "collectionviewer",
        component: CollectionViewer,
        meta: { requiresAuth: false, onlyAnonymous: false },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  const isAuthenticated = authStore.hasToken;
  if (to.meta.requiresAuth && !isAuthenticated) {
    next({ name: "login" });
  } else if (to.meta.onlyAnonymous && isAuthenticated) {
    next({ name: "workspace" });
  } else {
    next();
  }
});

export default router;
