import axios from "axios";
import { $useRemoteApi } from "@/plugins/globalProperties";

export const containerInstance = axios.create({
  baseURL:
    process.env.NODE_ENV === "production" || $useRemoteApi
      ? "https://sinkit.graybay-4c50acea.northeurope.azurecontainerapps.io/"
      : "https://localhost:7265/",
  json: true,
});

export const getContainerOwner = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/owner`);
};

export const getContainerOwnerAvatar = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/owner/avatar`);
};

export const getFiles = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/files`);
};

export const getImages = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/images`);
};

export const getStars = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/stars`);
};

export const getFolders = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/folders`);
};

export const getShares = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}/shares`);
};

export const getDefaultShare = async (containerId) => {
  // eslint-disable-next-line prettier/prettier
  return await containerInstance.get(`/container/${containerId}/shares/default`);
};

export const addContainer = async (container) => {
  return await containerInstance.post("/container", container);
};

export const updateContainer = async (container) => {
  return await containerInstance.put("/container", container);
};

export const deleteContainer = async (containerId) => {
  return await containerInstance.delete(`/container/${containerId}`);
};

export const getContainer = async (containerId) => {
  return await containerInstance.get(`/container/${containerId}`);
};

export const updateContaierName = async (containerId, containerName) => {
  return await containerInstance.put(`/container/${containerId}/name`, {
    name: containerName,
  });
};
